import { useAuth0 } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { addOrder, getCustomers, getOrders } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { ListItem } from "../api/types/ListItem";
import { Order } from "../api/types/Order";
import BackButton from "../modules/backbutton";
import ListedOrders from "../modules/listedorders";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";

export default function Orders() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	// const customerID = localStorage.getItem("customerID");
	const tenant_name = localStorage.getItem("tenant_name");
	const tenantID = localStorage.getItem("tenant");

	const [customer, setCustomer] = useState<Customer | undefined>(undefined);
	const [customers, setCustomers] = useState<Customer[]>();
	const [customersList, setCustomersList] = useState<ListItem[]>();

	const [orders, setOrders] = useState<Order[]>([]);
	const [receivedAllOrders, setReceivedAllOrders] = useState(false);
	const [tenantOrders, setTenantOrders] = useState<Order[] | undefined>([]);

	const [showOrderEntry, setShowOrderEntry] = useState(false);
	const [showOrderCreated, setShowOrderCreated] = useState(false);

	const [newReferenceID, setNewReferenceID] = useState();
	const [orderID, setOrderID] = useState();

	const [orderIdFilter, setOrderIdFilter] = useState<string>("");
	const [orderExactIdFilter, setExactIdFilter] = useState<string>("");

	const handleOrderEntryOpen = () => {
		setShowOrderEntry(true);
	};
	const handleOrderEntryClose = () => setShowOrderEntry(false);

	const handleOrderCreatedOpen = () => setShowOrderCreated(true);
	const handleOrderCreatedClose = () => setShowOrderCreated(false);

	function checkValidInput(inputValue: string): boolean {
		if (inputValue.length !== 0) return true;
		return false;
	}

	function handleChangeCustomer(event: any) {
		if (event === null) {
			setCustomer(undefined);
		} else {
			setCustomer(customers?.find((e) => e.id === event.value));
		}
	}
	async function AddOrder(event: any) {
		event.preventDefault();
		if (!checkValidInput(event.target.form.reference_id.value)) {
			toastManager("error", "Not all fields filled in", "Not all required fields are filled in");
			return;
		}
		if (customer) {
			const t = await addOrder(customer.id, customer?.tenant_id, event.target.form.reference_id.value);
			handleOrderEntryClose();
			setNewReferenceID(t.referenceId);
			setOrderID(t.id);
			setReceivedAllOrders(false);
			handleOrderCreatedOpen();
			toastManager("success", "Added new order", `Successfully added order to ${customer.name} with the reference '${event.target.form.reference_id.value}'`);
		} else {
			toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		}
	}

	useEffect(
		function RetrieveCustomers() {
			if (token && token.length) {
				var tempList: ListItem[] = [];
				getCustomers(token).then((response) => {
					setCustomers(response as Customer[]);
					if (response)
						response.forEach((cust: Customer) => {
							if (cust.tenant_id === tenantID)
								tempList.push({
									label: cust.name,
									value: cust.id,
								});
						});
					setCustomersList(tempList);
				});
			}
		},
		[tenantID, token]
	);

	useEffect(
		function FilterOrders() {
			var temp: Order[] = [];
			if (orders.length !== 0) {
				orders.forEach((order: Order) => {
					if (order.tenant_id === tenantID) {
						if (customer) {
							if (customer.id === order.customer_id) temp.push(order);
						} else temp.push(order);
					}
				});
				setTenantOrders(temp);
			}
		},
		[customer, orders, tenantID]
	);

	useEffect(
		function RetrieveOrders() {
			if (!receivedAllOrders) {
				if (token && token.length)
					getOrders(token).then((response) => {
						if (response) {
							setOrders(response);
							setReceivedAllOrders(true);
						}
					});
			}
		},
		[receivedAllOrders, token]
	);

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>

						{customers && (
							<>
								<Row className="center">
									<Col>
										<h4>{tenant_name}</h4>
										<h1>Orders </h1>
										<hr />
									</Col>
								</Row>
								<Row className="center">
									<Col sm>
										<Button
											className="rounded-pill"
											size="lg"
											onClick={handleOrderEntryOpen}
											variant="outline-primary"
										>
											<Icon.CartPlusFill /> <b>Add a new order</b>
										</Button>
									</Col>
								</Row>
								<br /> <br />
								<Container className="full-border">
									<Row className="bottom-border sales-filter">
										<Col
											sm={1}
											className="center"
										>
											<Image
												height={"auto"}
												width={"40px"}
												src="building.svg"
											/>
										</Col>
										<Col sm={3}>
											<Select
												className="fw-bold"
												placeholder="Customer name"
												isClearable
												options={customersList}
												onChange={handleChangeCustomer}
											/>
										</Col>
										<Col>
											<Form.Control
												className="fw-bold"
												placeholder="Reference"
												type="text"
												onChange={(e) => setOrderIdFilter(e.target.value)}
											/>
										</Col>
										<Col sm={2}>
											<Form.Control
												className="fw-bold"
												placeholder="Exact order ID"
												onChange={(e) => setExactIdFilter(e.target.value)}
											/>
										</Col>
										<Col
											sm={2}
											className="center fw-bold"
										>
											<Form.Label> Status</Form.Label>
										</Col>
										<Col
											sm={1}
											className="fw-bold"
										>
											<Form.Label> Order created</Form.Label>
										</Col>
										<Col
											sm={1}
											className="fw-bold"
										>
											<Form.Label>Last updated</Form.Label>
										</Col>
									</Row>
									<Row>
										<ListedOrders
											orders={tenantOrders ?? []}
											order_id_filter={orderIdFilter}
											order_exact_id_filter={orderExactIdFilter}
											customers={customers}
										/>
									</Row>
								</Container>
								<Modal
									show={showOrderEntry}
									onHide={handleOrderEntryClose}
									backdrop="static"
									keyboard
									centered
								>
									<Form validated>
										<Modal.Header closeButton>
											<Modal.Title>
												Add a new order for: <br />
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form.Text>Customer: </Form.Text>
											<Select
												options={customersList}
												onChange={handleChangeCustomer}
												placeholder={customer?.name}
											/>
											<br />
											<Form.Text>Add a reference id:</Form.Text>
											<Form.Control
												maxLength={20}
												autoComplete="false"
												required
												type="text"
												id="reference_id"
												disabled={customer === undefined}
											></Form.Control>
											<Form.Text>
												<i>Max. 20 characters</i>
											</Form.Text>
										</Modal.Body>
										<Modal.Footer>
											<Button
												variant="secondary"
												onClick={handleOrderEntryClose}
											>
												Cancel
											</Button>
											<Button
												variant="primary"
												type="submit"
												onClick={AddOrder}
											>
												Save order
											</Button>
										</Modal.Footer>
									</Form>
								</Modal>
								<Modal
									show={showOrderCreated}
									onHide={handleOrderCreatedClose}
									keyboard={true}
									centered
								>
									<Modal.Header closeButton>
										<Modal.Title>
											Added a new order for: {customer?.name}
											<br />
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form>
											<br />
											<Form.Text>
												With reference id:
												<Form.Control
													id="reference"
													type="text"
													disabled
													value={newReferenceID}
												></Form.Control>
											</Form.Text>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<Button
											variant="secondary"
											type="button"
											onClick={handleOrderCreatedClose}
										>
											Close
										</Button>
										<Button
											autoFocus
											className="right"
											variant="primary"
											type="submit"
											onClick={() => {
												navigate(`/orders/orderdetails?orderid=${orderID}`);
												handleOrderCreatedClose();
											}}
										>
											Add Order details
										</Button>
									</Modal.Footer>
								</Modal>
							</>
						)}
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn/>}
		</>
	);
}
