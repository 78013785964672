import { useAuth0 } from "@auth0/auth0-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { getBagCodes, getTenant } from "../api/orderportal_apimanager";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { CreateBagCodeSheet } from "../modules/pdf/createBagCodeSheet";
import { useJwt } from "react-jwt";
import HasPermission, { Permissions } from "../modules/permissionCheck";

export default function AdminPanel() {
	const { isAuthenticated, user } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);
	const { decodedToken } = useJwt<any>(token);

	const [tenant, setTenant] = useState<Tenant | undefined>();
	const isAuthorized: boolean = true;
	const [barcodesFromAPI, setBarcodesFromAPI] = useState<string[]>([]);

	const tenantID = localStorage.getItem("tenant");

	const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);

	useEffect(
		function RetrieveTenant() {
			if (token && tenantID)
				getTenant(token, tenantID).then((response) => {
					setTenant(response as Tenant);
				});
		},
		[tenantID, token]
	);

	function getBarcodesFromApi(event: any) {
		if (tenantID && token)
			getBagCodes(token, tenantID, event.target.form["pdf-pages"].value).then((response) => {
				setBarcodesFromAPI(response);
				setShowDownloadButton(!showDownloadButton);
			});
	}
	return (
		<>
			<Navigation />
			{isAuthenticated && isAuthorized && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>

						<h1>Administrator panel </h1>
						<Row>
							<Col></Col>
							<Col>All actions here are portal wide and will be logged.</Col>
							<Col></Col>
						</Row>
						<br />
						<Row className="gy-4 admin">
							{HasPermission(decodedToken, Permissions.BRANDCONFIG_DESIGNER, true) && (
								<Col sm={4}>
									<Card
										className="admin-card"
										onClick={() => navigate("brandconfig")}
									>
										<Card.Header>
											<p className="admin">
												<Icon.Palette2 />
												<br /> Brand configuration{" "}
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}
							{HasPermission(decodedToken, Permissions.BRANDCONFIG_ADMIN, true) && (
								<Col sm={4}>
									<Card
										className="admin-card"
										border="none"
										onClick={() => navigate("productconfig")}
									>
										<Card.Header>
											<p>
												<Icon.Tools />
												<br />
												Product configuration
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}

							<Col
								className="admin-card"
								sm={4}
							>
								<Card
									border="none"
									// onClick={() => navigate("/logs")}q
								>
									<Card.Header>
										<p>
											{" "}
											<Icon.ListColumnsReverse />
											<br /> Logs
										</p>
									</Card.Header>
								</Card>
							</Col>
							<Row style={{ paddingTop: 20, paddingLeft: 25 }}>
								{showDownloadButton ? (
									<Col className="tenant-cards">
										<Card border="none">
											<PDFDownloadLink
												document={<CreateBagCodeSheet barcodes={barcodesFromAPI} />}
												fileName={`BagCodeSheet.pdf`}
												onClick={() => setTimeout(() => setShowDownloadButton(!showDownloadButton), 50)}
											>
												{/* @ts-ignore */}
												{({ blob, url, loading, error }) =>
													loading ? (
														<Card.Header>
															<p>
																{" "}
																<Spinner
																	animation="border"
																	color="red"
																/>
																<br /> Loading Impression Codes
															</p>
														</Card.Header>
													) : (
														<Card.Header>
															<p>
																{" "}
																<Icon.QrCode />
																<br /> Download Impression Codes
															</p>
														</Card.Header>
													)
												}
											</PDFDownloadLink>
										</Card>
									</Col>
								) : (
									<Form>
										<Card>
											<Card.Header>
												<p>
													{" "}
													<Icon.QrCode />
													<br /> Impression Codes
												</p>
												<Row>
													<Col sm={4}>
														<h4>Amount of code pages</h4>
													</Col>
													<Col>
														<Form.Control
															size="lg"
															type="number"
															id="pdf-pages"
															min={1}
															max={10}
															defaultValue={2}
														></Form.Control>
													</Col>
													<Col>
														<Button
															size="lg"
															onClick={(event) => {
																getBarcodesFromApi(event);
															}}
														>
															Generate Barcodes
														</Button>
													</Col>
												</Row>
											</Card.Header>
										</Card>
									</Form>
								)}
							</Row>
						</Row>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
