import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getCustomers, getOrders, getTenants, searchConsumer, searchImpressionID, searchProductionNumber, searchSerialNumber } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { Order } from "../api/types/Order";
import { SearchConsumerResponseType } from "../api/types/SearchConsumerResponseType";
import { SearchProductionNumberResponseType } from "../api/types/SearchProductionNumberResponseType";
import { SearchSerialResponseType } from "../api/types/SearchSerialResponseType";
import { Tenant } from "../api/types/Tenant";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { SearchImpressionResponseType } from "../api/types/SearchImpressionsResponseType";
import { Consumer } from "../api/types/Consumer";

export default function Search() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const searchFilter = [
		{
			label: "Consumer",
			value: "Consumer",
		},
		{
			label: "Customer",
			value: "Customer",
		},
		{
			label: "Order reference",
			value: "Order reference",
		},
		{
			label: "Exact ID",
			value: "Exact ID",
		},
		{
			label: "Serial number",
			value: "Serial number",
		},
		{
			label: "PR number",
			value: "PR number",
		},
		{
			label: "Impression",
			value: "Impression",
		},
	];

	const [orders, setOrders] = useState<Order[]>();
	const [filteredOrders, setFilteredOrders] = useState<Order[]>();

	const [customers, setCustomers] = useState<Customer[]>();
	const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>();

	const [filteredSerialNumbers, setFilteredSerialNumbers] = useState<SearchSerialResponseType[]>();

	const [filteredConsumers, setFilteredConsumers] = useState<SearchConsumerResponseType[]>();

	const [filteredProductionOrders, setFilteredProductionOrders] = useState<SearchProductionNumberResponseType[]>();

	const [filteredImpressions, setFilteredImpressions] = useState<SearchImpressionResponseType[]>();

	const [tenants, setTenants] = useState<Tenant[]>();

	const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);
	const [searchValue, setSearchValue] = useState<string>();

	function handleSearchValue(event: any) {
		setSearchValue(event.target.value);
		if (event.target.value === undefined || event.target.value.length === 0) {
			setFilteredOrders(undefined);
			setFilteredCustomers(undefined);
			return;
		}
		if (selectedFilter === "Order reference") {
			const temp = orders?.filter((e) => e.referenceId.toLowerCase().includes(event.target.value.toLowerCase()));
			setFilteredOrders(temp);
		}
		if (selectedFilter === "Exact ID") {
			const temp = orders?.filter((e) => e?.exact_order_number?.toString().includes(event.target.value));
			setFilteredOrders(temp);
		}
		if (selectedFilter === "Customer") {
			const temp = customers?.filter((e) => e.name.toLowerCase().includes(event.target.value.toLowerCase()));
			setFilteredCustomers(temp);
		}
		if (selectedFilter === "Serial number") {
			// minimum of 3 characters is required for the search
			if (event.target.value.length < 3) {
				setFilteredSerialNumbers(undefined);
				return;
			}
			searchSerialNumber(token, event.target.value.toUpperCase()).then((response) => {
				if (response) setFilteredSerialNumbers(response);
				else setFilteredSerialNumbers(undefined);
			});
		}
		if (selectedFilter === "Consumer") {
			// minimum of 2 characters is required for the search
			if (event.target.value.length < 2) {
				setFilteredConsumers(undefined);
				return;
			}
			searchConsumer(token, event.target.value.toLowerCase()).then((response) => {
				if (response) setFilteredConsumers(response);
				else setFilteredConsumers(undefined);
			});
		}
		if (selectedFilter === "PR number") {
			// minimum of 2 characters is required for the search
			if (event.target.value.length < 3) {
				setFilteredProductionOrders(undefined);
				return;
			}
			searchProductionNumber(token, event.target.value.toUpperCase()).then((response) => {
				if (response) setFilteredProductionOrders(response);
				else setFilteredProductionOrders(undefined);
			});
		}
		if (selectedFilter === "Impression") {
			// minimum of 2 characters is required for the search
			if (event.target.value.length < 3) {
				setFilteredImpressions(undefined);
				return;
			}
			searchImpressionID(token, event.target.value.toUpperCase()).then((response) => {
				if (response) setFilteredImpressions(response);
				else setFilteredImpressions(undefined);
			});
		}
	}

	function navigateToOrder(order: Order) {
		var customer = customers?.find((e) => e.id === order.customer_id);
		var tenant = tenants?.find((e) => e.id === customer?.tenant_id);

		localStorage.setItem("tenant_name", tenant?.name ?? "");
		localStorage.setItem("tenant", tenant?.id ?? "");
		navigate(`/orders/orderdetails?orderid=${order.id}`);
	}

	function navigateToCustomer(customer: Customer) {
		localStorage.setItem("tenant", customer.tenant_id);
		var tenant = tenants?.find((e) => e.id === customer.tenant_id);
		localStorage.setItem("tenant_name", tenant?.name ?? "");
		navigate(`/companies?customer=${customer.id}`);
	}

	function navigateToSerialNumber(serial: SearchSerialResponseType) {
		localStorage.setItem("tenant", serial.tenant);
		localStorage.setItem("tenant_name", serial.tenant_name ?? "");
		navigate(`/profile?user=${serial.consumer}&customer=${serial.customer}&product=${serial.serial_cradle}`);
	}

	function navigateToConsumer(consumer: SearchConsumerResponseType) {
		localStorage.setItem("tenant", consumer.tenant);
		localStorage.setItem("tenant_name", consumer.tenant_name ?? "");
		navigate(`/profile?user=${consumer.id}&customer=${consumer.customer}`);
	}
	function navigateToImpression(impression: SearchImpressionResponseType) {
		localStorage.setItem("tenant", impression.tenant);
		localStorage.setItem("tenant_name", tenants?.find((e) => e.id === impression.tenant)?.name ?? "");
		navigate(`/profile?user=${impression.consumer_id}&customer=${impression.customer_id}&impression=${impression.id}`);
	}

	function navigateToChiselOrder(production_order: SearchProductionNumberResponseType) {
		var tenant = tenants?.find((e) => e.id === production_order?.tenant);

		localStorage.setItem("tenant", tenant?.id ?? "");
		localStorage.setItem("tenant_name", tenant?.name ?? "");
		navigate(`/orders/orderdetails?orderid=${production_order.id}`);
	}

	function PopulateFilteredOrders() {
		var temp_array: JSX.Element[] = [];
		if (filteredOrders?.length === 0 || filteredOrders === undefined || (searchValue && searchValue.length <= 1))
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredOrders === undefined ? "No search input" : `Could not find any orders containing this search, minimum length is 2 characters`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredOrders && searchValue && searchValue.length >= 2)
			filteredOrders.forEach((order) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToOrder(order)}
							key={order.id}
						>
							<Row>
								<Col>{order.referenceId}</Col>
								<Col>{customers?.find((e) => e.id === order.customer_id)?.name}</Col>
								<Col sm={2}>{order.exact_order_number === -1 ? "Unassigned" : order.exact_order_number}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}

	function PopulateFilteredCustomers() {
		var temp_array: JSX.Element[] = [];
		if (filteredCustomers?.length === 0 || filteredCustomers === undefined)
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredCustomers === undefined ? "No search input" : `Could not find any customers containing this search`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredCustomers)
			filteredCustomers.forEach((customer) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToCustomer(customer)}
							key={customer.id}
						>
							<Row>
								<Col>{customer.name}</Col>
								<Col>{tenants?.find((e) => e.id === customer.tenant_id)?.name}</Col>
								<Col sm={3}>{customer.contact_email}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}

	function PopulateFilteredSerialNumbers() {
		var temp_array: JSX.Element[] = [];
		if (filteredSerialNumbers === undefined || filteredSerialNumbers?.length <= 0)
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredCustomers === undefined ? "Minimum input for search is 3 characters" : `Could not find any serial numbers containing this search`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredSerialNumbers)
			filteredSerialNumbers.forEach((serialNumber) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToSerialNumber(serialNumber)}
							key={serialNumber.id}
						>
							<Row>
								<Col>{serialNumber.tenant_name}</Col>
								<Col>{serialNumber.customer_name}</Col>
								<Col>{serialNumber.serial_left}</Col>
								<Col>{serialNumber.serial_right}</Col>
								<Col>{serialNumber.serial_cradle}</Col>
								<Col sm={1}>{serialNumber.color_code}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}

	function PopulateFilteredConsumers() {
		var temp_array: JSX.Element[] = [];
		if (filteredConsumers === undefined || filteredConsumers?.length <= 0)
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredCustomers === undefined ? "Minimum input for search is 2 characters" : `Could not find any consumers containing this search`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredConsumers)
			filteredConsumers.forEach((consumer) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToConsumer(consumer)}
							key={consumer.id}
						>
							<Row>
								<Col>{consumer.consumer_name_var1}</Col>
								<Col>{consumer.tenant_name}</Col>
								<Col>{consumer.customer_name}</Col>
								<Col>{consumer.department_name}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}
	function PopulateFilteredProductionOrders() {
		var temp_array: JSX.Element[] = [];
		if (filteredProductionOrders === undefined || filteredProductionOrders?.length <= 0)
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredCustomers === undefined ? "Minimum input for search is 3 characters" : `Could not find any production orders containing this search`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredProductionOrders)
			filteredProductionOrders.forEach((PO) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToChiselOrder(PO)}
							key={PO.id}
						>
							<Row>
								<Col>
									<a
										className="pr-number-link "
										target="_blank"
										href={`${process.env.REACT_APP_CHISEL_LINK}/order/${PO.pr_number}`}
										rel="noreferrer"
									>
										{PO.pr_number}
									</a>
								</Col>
								<Col>{customers?.find((e) => e.id === PO.customer)?.name}</Col>
								<Col>{PO.so_ref}</Col>
								<Col>{PO.so}</Col>
								<Col className="text-capitalize">{PO?.last_status?.data?.status.toLowerCase().replace("_", " ")}</Col>
								<Col>{PO.last_status?.data?.eventDate?.substring(0, PO.last_status?.data?.eventDate?.lastIndexOf("."))}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}
	function PopulateFilteredImpressions() {
		var temp_array: JSX.Element[] = [];
		if (filteredImpressions === undefined || filteredImpressions?.length <= 0)
			temp_array.push(
				<>
					<ListGroup.Item>
						<Row>
							<Col>{filteredCustomers === undefined ? "Minimum input for search is 3 characters" : `Could not find any impressions containing this search`}</Col>
						</Row>
					</ListGroup.Item>
				</>
			);

		if (filteredImpressions)
			filteredImpressions.forEach((impression) => {
				temp_array.push(
					<>
						<ListGroup.Item
							action
							onClick={() => navigateToImpression(impression)}
							key={impression.id}
						>
							<Row>
								<Col className="text-truncate">{tenants?.find((e) => e.id === impression.tenant)?.name}</Col>
								<Col className="text-truncate">{customers?.find((e) => e.id === impression.customer_id)?.name}</Col>
								<Col>{impression.imp_serial_left}</Col>
								<Col>{impression.imp_serial_right}</Col>
								<Col>{impression.bag_code_left}</Col>
								<Col>{impression.method}</Col>
							</Row>
						</ListGroup.Item>
					</>
				);
			});
		return <>{temp_array}</>;
	}

	useEffect(
		function getOrdersFromAPI() {
			getOrders(token).then((response) => {
				if (response) {
					var temp: Order[] = [];
					response.forEach((order: Order) => {
						if (order.exact_order_number === null) order.exact_order_number = -1;
						temp.push(order);
					});
					setOrders(temp);
				}
			});
		},
		[token]
	);

	useEffect(
		function getCustomersFromAPI() {
			getCustomers(token).then((response) => {
				if (response) setCustomers(response);
			});
		},
		[token]
	);

	useEffect(
		function getTenantsFromAPI() {
			getTenants(token).then((response) => {
				if (response) setTenants(response);
			});
		},
		[token]
	);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route={`/home`}
						title="Home"
					/>

					<h1>Search in portal</h1>

					<Row>
						<Col sm={3}>
							<Select
								isSearchable
								options={searchFilter}
								onChange={(e) => {
									setSelectedFilter(e?.value);
									setSearchValue("");
								}}
								placeholder={"Please select a filter"}
							/>
						</Col>
						<Col>
							<Form.Control
								onChange={handleSearchValue}
								placeholder="Enter your search here"
								value={searchValue}
								disabled={selectedFilter === undefined}
							/>
						</Col>
					</Row>
					<br />

					{selectedFilter === "Consumer" && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold ">Consumer</Col>
									<Col className="fw-bold">Tenant</Col>
									<Col className="fw-bold">Customer</Col>
									<Col className="fw-bold">Department</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredConsumers />
						</ListGroup>
					)}
					{selectedFilter === "Customer" && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">Name</Col>
									<Col className="fw-bold">Tenant</Col>
									<Col
										sm={3}
										className="fw-bold"
									>
										email
									</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredCustomers />
						</ListGroup>
					)}
					{(selectedFilter === "Order reference" || selectedFilter === "Exact ID") && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">Reference</Col>
									<Col className="fw-bold">Customer</Col>
									<Col
										sm={2}
										className="fw-bold"
									>
										Exact ID
									</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredOrders />
						</ListGroup>
					)}

					{selectedFilter === "Serial number" && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">Tenant</Col>
									<Col className="fw-bold">Customer</Col>
									<Col className="fw-bold">Left serial</Col>
									<Col className="fw-bold">Right serial</Col>
									<Col className="fw-bold">Cradle serial</Col>
									<Col
										className="fw-bold"
										sm={1}
									>
										Color
									</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredSerialNumbers />
						</ListGroup>
					)}
					{selectedFilter === "PR number" && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">PR number</Col>
									<Col className="fw-bold">Customer</Col>
									<Col className="fw-bold">Order reference</Col>
									<Col className="fw-bold">Exact ID</Col>
									<Col className="fw-bold">Status</Col>
									<Col className="fw-bold">Status date</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredProductionOrders />
						</ListGroup>
					)}
					{selectedFilter === "Impression" && (
						<ListGroup>
							<ListGroup.Item>
								<Row>
									<Col className="fw-bold">Tenant</Col>
									<Col className="fw-bold">Customer</Col>
									<Col className="fw-bold">Serial left</Col>
									<Col className="fw-bold">Serial right</Col>
									<Col className="fw-bold">Bag code</Col>
									<Col className="fw-bold">Method</Col>
								</Row>
							</ListGroup.Item>
							<PopulateFilteredImpressions />
						</ListGroup>
					)}
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
