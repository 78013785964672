import React, { PropsWithChildren } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import Layout from "./layout";
import AdminPanel from "./routes/adminpanel";
import Appointment from "./routes/appointment";
import Appointments from "./routes/appointments";
import BrandConfig from "./routes/brandconfig";
import Companies from "./routes/companies";
import Company from "./routes/company";
import LandingPage from "./routes/landingpage";
import OrderDetails from "./routes/orderdetails";
import Orders from "./routes/orders";
import PrintBatches from "./routes/printbatches";
import ProductConfig from "./routes/productconfig";
import Production from "./routes/production";
import Profile from "./routes/profile";
import RMA from "./routes/rma";
import ScanAndModel from "./routes/scanandmodel";
import Search from "./routes/search";
import Shipping from "./routes/shipping";
import Status from "./routes/status";
import Logs from "./routes/logs";
import { AppState, Auth0Provider, Auth0ProviderOptions, withAuthenticationRequired } from "@auth0/auth0-react";

function App() {
	const ProtectedRoute = (component: any, ...args: any) => {
		const Component = withAuthenticationRequired(component, args);
		return <Component />;
	};
	return (
		<>
			{/* <Auth0Provider
				useRefreshTokens={true}
				useRefreshTokensFallback={true}
				onRedirectCallback={onRedirectCallback}
				authorizationParams={{
					redirect_uri: window.location.origin,
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
					scope: process.env.REACT_APP_AUTH0_SCOPE,
				}}
			> */}
			<Toaster
				position="top-right"
				richColors
				expand
				visibleToasts={20}
			/>

			<Routes>
				<Route
					path="/"
					element={<Layout />}
				>
					<Route
						path=""
						element={<LandingPage />}
					/>
					<Route
						path="home"
						element={<LandingPage />}
					/>
					<Route
						path="orders"
						element={<Orders />}
					/>
					<Route
						path="orders/orderdetails"
						element={<OrderDetails />}
					/>
					<Route
						path="scanandmodel"
						element={<ScanAndModel />}
					/>
					<Route
						path="appointments"
						element={<Appointments />}
					/>
					<Route
						path="appointments/appointment"
						element={<Appointment />}
					/>
					<Route
						path="profile"
						element={<Profile />}
					/>
					<Route
						path="company"
						element={<Company />}
					/>
					<Route
						path="companies"
						element={<Companies />}
					/>
					<Route
						path="rma"
						element={<RMA />}
					/>
					<Route
						path="production"
						element={<Production />}
					/>
					<Route
						path="printbatches"
						element={<PrintBatches />}
					/>
					<Route
						path="shipping"
						element={<Shipping />}
					/>
					<Route
						path="status"
						element={<Status />}
					/>
					<Route
						path="search"
						element={<Search />}
					/>
					<Route
						path="logs"
						element={<Logs />}
					/>
					<Route
						path="adminpanel"
						element={<AdminPanel />}
					/>
					<Route
						path="adminpanel/brandconfig"
						element={<BrandConfig />}
					/>
					<Route
						path="adminpanel/productconfig"
						element={<ProductConfig />}
					/>
					<Route
						path="*"
						element={<LandingPage />}
					/>
				</Route>
			</Routes>
			<footer className="footer">
				&copy; 2024 EarsOnly - <small> version: {process.env.REACT_APP_VERSION_NUMBER}</small>{" "}
				<small className="footer-right d-none d-lg-block">
					Contact: <a href="https://earsonly.nl/contact">earsonly.nl/contact</a> | <a href="https://earsonly.nl/terms">Terms & conditions</a>{" "}
				</small>{" "}
			</footer>
			{/* </Auth0Provider> */}
		</>
	);
}
export default App;
