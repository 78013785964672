import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { createCDNClient } from "../api/cdn_apimanager";
import { getCustomers, getTenant, getTenants, getToBeModeled, getToBeScanned } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { Impression } from "../api/types/Impression";
import { Priority } from "../api/types/Priority";
import { Tenant } from "../api/types/Tenant";
import { ToBeModeledType } from "../api/types/ToBeModeledType";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import ToBeModeled from "../modules/tobemodeled";
import ToBeScanned from "../modules/tobescanned";
import toastManager from "../modules/toastmanager";
import { ListItem } from "../api/types/ListItem";
import Select from "react-select";

export default function ScanAndModel() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const queryParameters = new URLSearchParams(window.location.search);
	const tenantID = localStorage.getItem("tenant");
	const [customers, setCustomers] = useState<Customer[]>();

	const [tenants, setTenants] = useState<Tenant[]>();
	const [selectedTenant, setSelectedTenant] = useState<ListItem>();
	const [tenantsList, setTenantsList] = useState<ListItem[]>();

	const [impressions, setImpressions] = useState<Impression[]>([]);
	const [scans, setScans] = useState<ToBeModeledType[]>([]);

	const [reload, setReload] = useState<boolean>(true);

	// const priority = new Priority();

	const [showModal, setShowModal] = useState<boolean>(false);
	const handleCloseModal = () => setShowModal(false);
	const handleOpenModal = () => setShowModal(true);

	function handleTenantChange(event: any) {
		setReload(true);
		if (event === null) setSelectedTenant(undefined);
		else {
			setSelectedTenant(event);
			localStorage.setItem("tenant", event.value);
			localStorage.setItem("tenant_name", event.label);
		}
	}

	useEffect(
		function CreateCDNClient() {
			createCDNClient(token);
		},
		[token]
	);

	useEffect(
		function RetrieveCustomers() {
			if (!customers && token && token.length) {
				getCustomers(token).then((response) => {
					setCustomers(response as Customer[]);
				});
			}
		},
		[customers, token, reload]
	);

	useEffect(
		function RetrieveImpressions() {
			if (selectedTenant?.value && token && token.length && reload)
				getToBeScanned(token, selectedTenant?.value).then((response) => {
					setImpressions(response);
					setReload(false);
				});
		},
		[selectedTenant?.value, token, reload]
	);

	useEffect(
		function RetrieveScans() {
			if (selectedTenant?.value && token && token.length && reload)
				getToBeModeled(token, selectedTenant?.value).then((response) => {
					setScans(response);
					setReload(false);
				});
		},
		[selectedTenant?.value, token, reload]
	);

	useEffect(
		function getTennantsFromAPI() {
			getTenants(token).then((response) => {
				if (response) {
					setTenants(response);
					var temp: ListItem[] = [];
					response.forEach((ten: Tenant) => {
						temp.push({ label: ten.name, value: ten.id });
					});
					setTenantsList(temp);
					setSelectedTenant(temp.find((e) => e.value === tenantID));
				}
			});
		},
		[tenantID, token]
	);

	function PopulateImpressions() {
		var impressionsArray: JSX.Element[] = [];
		var key = 0;
		if (impressions && tenantID && customers)
			impressions.forEach((impression) => {
				impressionsArray.push(
					<ToBeScanned
						key={key}
						tenant_id={tenantID}
						impression={impression}
						customers={customers}
						// priority={priority.iconNormal}
					/>
				);
				key++;
			});
		if (impressionsArray !== undefined) return <>{impressionsArray}</>;
		else return <></>;
	}

	function PopulateScans() {
		var scansArray: JSX.Element[] = [];
		if (scans && tenantID && customers)
			scans.forEach((scan) => {
				scansArray.push(
					<ToBeModeled
						key={scan.id}
						scan={scan}
						tenant_id={tenantID}
						customers={customers}
						// priority={priority.iconNormal}
						token={token}
					/>
				);
			});
		if (scansArray !== undefined) return <>{scansArray}</>;
		else return <></>;
	}

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<Container className="paddingTopBottom">
					<BackButton
						route="/home"
						title="homepage"
					/>
					<>
						<Row className="center">
							<Col>
								<h4>{selectedTenant?.label}</h4>
								<h1>
									Scan and Model <Icon.Info onClick={handleOpenModal} />
								</h1>
							</Col>
						</Row>
						<br />

						<hr />
						<Row>
							<Col sm={3}>
								<Select
									value={selectedTenant}
									onChange={handleTenantChange}
									options={tenantsList}
								/>
							</Col>
							<Col>
								<Button onClick={() => setReload(true)}>
									<Icon.ArrowCounterclockwise />
								</Button>
							</Col>
						</Row>
						<br />
						<br />
						<Row>
							{impressions.length === 1 ? (
								<h4>
									<b>{impressions.length}</b> Impression to be scanned:
								</h4>
							) : (
								<h4>
									<b>{impressions.length}</b> Impressions to be scanned:
								</h4>
							)}
							<Row className="fw-bold">
								<Col sm={2}>Customer</Col>
								<Col sm={1}>Bag code</Col>
								<Col sm={2}>Left scan</Col>
								<Col sm={2}></Col>
								<Col sm={2}>Right scan</Col>
								<Col
									className="right"
									sm={3}
								>
									Uploaded
								</Col>
							</Row>
							<hr />
							<ListGroup variant="flush">
								<PopulateImpressions />
							</ListGroup>
						</Row>
						<br />
						<br />
						<br />
						<Row>
							{scans.length === 1 ? (
								<h4>
									<b>{scans.length}</b> Scan to be modeled:
								</h4>
							) : (
								<h4>
									<b>{scans.length}</b> Scans to be modeled:
								</h4>
							)}

							<hr />
							<ListGroup variant="flush">
								<PopulateScans />
							</ListGroup>
						</Row>
					</>
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
			<Modal
				size="xl"
				show={showModal}
				onHide={handleCloseModal}
			>
				<Modal.Header closeButton>
					<h2>Modeling instruction</h2>
				</Modal.Header>
				Sensitive information will maybe be added later.
				{/* <Modal.Body>
					<object
						className="pdf-modal"
						data="/CP5_modeling_instruction.pdf"
						type="application/pdf"
						width="100%"
						height="100%"
					>
						<p>Open this page on desktop to view the PDF!</p>
					</object>
				</Modal.Body> */}
			</Modal>
		</>
	);
}
